import Vue from 'vue'
import VueRouter from 'vue-router'
import adminRouter from "@/router/admin";
// import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: () => {
            return '/login'
        }
    },
    {
        path: '/login',
        component: () =>
            import ('../views/admin/login'),
        meta: {
            title: '善远HR管理系统-登录',
            isAdmin:false,
            needLogin: false // 是否需要登录才能访问
        }
    }, {
        path: '/offerInfo/:id/:type/:state',
        component: () =>
            import ('../views/admin/offerInfo'),
        meta: {
            title: '善远HR管理系统-Offer Letter',
            isAdmin:false,
            needLogin: false // 是否需要登录才能访问
        }
    }, {
        path: '/staffInfo/:id/:type',
        component: () =>
            import ('../views/admin/staffInfo'),
        meta: {
            title: '善远HR管理系统-员工信息登记表',
            isAdmin:false,
            needLogin: false // 是否需要登录才能访问
        }
    }, {
        path: '/roleList',
        component: () =>
            import ('../views/admin/roleList'),
        meta: {
            title: '公司选择',
            needLogin: true,isAdmin:false
        }
    },
    {
        path: '/admin',
        component: () =>
            import ('../views/admin/home'),
        children: adminRouter,
    },
    //  404
    {
        path: '*',
        component: () => import('../views/admin/404'),
        meta: {
            title: '页面找不到了',
            isAdmin:false,
            needLogin: false // 是否需要登录才能访问
        }
    }

]

const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach((to, from, next) => {
    // console.log(to.meta.isAdmin)
    // console.log(localStorage.getItem('token'))
    // console.log(JSON.parse(localStorage.getItem('isAdmin')))
    let _isAdmin  =localStorage.getItem('isAdmin') ? JSON.parse(localStorage.getItem('isAdmin')) : false
    let _token  =localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : false
    //  axj1292
    if(to.meta.needLogin && !_token){
        next('/login');
    }

    if(to.meta.isAdmin && _isAdmin){
        next();
    }else if(to.meta.isAdmin && !_isAdmin){
        next('/admin/needList');
    }else if(!to.meta.isAdmin && !_isAdmin){
        next();
    }else if(!to.meta.isAdmin && _isAdmin){
        next('/admin/orgList');
    }else{
        next();
    }
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router
