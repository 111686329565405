"use strict";

import axios from "axios";
import router from '@/router/index'
import {Notification, MessageBox} from 'element-ui'
import {ONLINEHOST, QAHOST} from "../config";
import {getOrgId, getToken, isMobile} from "@/utils/index";
import store from '../store'
import Qs from 'qs';
import {Dialog} from "vant";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: (process.env.NODE_ENV === 'production' ? ONLINEHOST : QAHOST) + '/index.php',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};
const _isMobile = isMobile()

const _axios = axios.create(config);

_axios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Add a response interceptor
_axios.interceptors.response.use(response => {
  if (response.config.responseType === 'blob') {
    return response
  }
  if (response.data.status === 200 || response.data.status === 800) {
    return response.data
  } else if (response.data.status === 300 || response.data.status === 301 || response.data.status === 307) {
    return Notification.error({
      title: response.data.info, duration: 2500
    })
  } else {
    if (_isMobile) {
      Dialog.alert({
        title: '系统提示', message: '登录状态已过期，请您重新登录',
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          window.localStorage.clear()
          router.push(_isMobile ? '/mlogin' : '/login')
        })
      });
    } else {
      MessageBox.confirm('登录状态已过期，请您重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        type: 'warning',
        showClose: true,
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          window.localStorage.clear()
          router.push(_isMobile ? '/mlogin' : '/login')
        })
      })
    }

  }
}, error => {
  console.log(error)
  let code = 0
  try {
    code = error.response.data.status
  } catch (e) {
    console.log(e)
    if (error.toString().indexOf('Error: timeout') !== -1) {
      // Notification.error({
      //   title: '网络请求超时',
      //   duration: 2500
      // })
      return Promise.reject(error)
    }
    if (error.toString().indexOf('Error: Network Error') !== -1) {
      Notification.error({
        title: '网络请求错误', duration: 2500
      })
      return Promise.reject(error)
    }
  }
  if (code === 300 || code === 301) {
    if (isMobile()) {
      Dialog.alert({
        title: '系统提示', message: '登录状态已过期，请您重新登录',
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          window.localStorage.clear()
          router.push(_isMobile ? '/mlogin' : '/login')
        })
      });
    } else {
      MessageBox.confirm('登录状态已过期，请您重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        type: 'warning',
        showClose: true,
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          window.localStorage.clear()
          router.push(_isMobile ? '/mlogin' : '/login')
        })
      })
    }
  }
  return Promise.reject(error)
});

// 封装get方法和post方法
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    _axios.get(url, {
      params: params
    }).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err)
    })
  });
}

/**
 * getfile方法，对应get请求流文件
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function getfile(url, params) {
  return new Promise((resolve, reject) => {
    _axios.get(url, {
      params: params, responseType: 'blob'
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    _axios.post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}

/**
 * postfile方法，对应post请求流文件
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function qspostfile(url, params) {
  return new Promise((resolve, reject) => {
    _axios.post(url, Qs.stringify({...params, ...getToken(), ...getOrgId()}), {responseType: 'blob'})
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}

/**
 * qspost方法，参数序列化
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function qspost(url, params) {
  return new Promise((resolve, reject) => {
    _axios.post(url, Qs.stringify({...params, ...getToken(), ...getOrgId()}))
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
  return new Promise((resolve, reject) => {
    _axios.put(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function deletefn(url, params) {
  return new Promise((resolve, reject) => {
    _axios.delete(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}
