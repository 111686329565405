const adminRouter = [{
  path: '',
  redirect: 'needList'
},
  {
    path: 'needList',
    meta: {title: '待办事宜', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/needList/needList'),
  },


  {
    path: 'staffList',
    meta: {title: '员工信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/staffList/staffList'),
  },{
    path: 'staffDetail/:id',
    meta: {title: '员工信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/staffList/staffDetail'),
  },{
    path: 'staffAdd',
    meta: {title: '员工信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/staffList/staffAdd'),
  },
  {
    path: 'expertList',
    meta: {title: '员工入职', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/entryList'),
  },{
    path: 'fullTime/offerInfo/:id',
    meta: {title: '全职员工offer', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/fullTime/offerInfo.vue'),
  },{
    path: 'fullTime/staffInfo/:id',
    meta: {title: '全职员工信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/fullTime/staffInfo.vue'),
  },{
    path: 'fullTime/entryInfo/:id',
    meta: {title: '全职员工入职通知', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/fullTime/entryInfo.vue'),
  },{
    path: 'fullTime/contractInfo/:id',
    meta: {title: '全职员工劳务合同', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/fullTime/contractInfo.vue'),
  },{
    path: 'practiceTime/offerInfo/:id',
    meta: {title: '实习生offer', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/practiceTime/offerInfo.vue'),
  },{
    path: 'practiceTime/staffInfo/:id',
    meta: {title: '实习生信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/practiceTime/staffInfo.vue'),
  },{
    path: 'practiceTime/entryInfo/:id',
    meta: {title: '实习生入职通知', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/practiceTime/entryInfo.vue'),
  },{
    path: 'practiceTime/contractInfo/:id',
    meta: {title: '实习生劳务合同', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/practiceTime/contractInfo.vue'),
  },{
    path: 'partTime/offerInfo/:id',
    meta: {title: '兼职offer', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/partTime/offerInfo.vue'),
  },{
    path: 'partTime/staffInfo/:id',
    meta: {title: '兼职员工信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/partTime/staffInfo.vue'),
  },{
    path: 'partTime/entryInfo/:id',
    meta: {title: '兼职员工入职通知', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/partTime/entryInfo.vue'),
  },{
    path: 'partTime/contractInfo/:id',
    meta: {title: '兼职员工劳务合同', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/entryList/partTime/contractInfo.vue'),
  }, {
    path: 'fullTime/info/:id',
    meta: {title: '全职员工离职信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/fullTime/info.vue'),
  }, {
    path: 'fullTime/protocol/:id',
    meta: {title: '全职员工离职协议', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/fullTime/protocol.vue'),
  }, {
    path: 'fullTime/handover/:id',
    meta: {title: '全职员工离职交接表', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/fullTime/handover.vue'),
  }, {
    path: 'fullTime/salary/:id',
    meta: {title: '全职员工离职薪酬核算单', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/fullTime/salary.vue'),
  }, {
    path: 'fullTime/prove/:id',
    meta: {title: '全职员工离职证明', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/fullTime/prove.vue'),
  },{
    path: 'practiceTime/info/:id',
    meta: {title: '实习生离职信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/practiceTime/info.vue'),
  }, {
    path: 'practiceTime/protocol/:id',
    meta: {title: '实习生离职协议', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/practiceTime/protocol.vue'),
  }, {
    path: 'practiceTime/prove/:id',
    meta: {title: '实习生离职证明', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/practiceTime/prove.vue'),
  },
  {
    path: 'partTime/info/:id',
    meta: {title: '兼职员工信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/partTime/info.vue'),
  }, {
    path: 'partTime/protocol/:id',
    meta: {title: '兼职终止通知', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/partTime/protocol.vue'),
  },






  {
    path: 'resignationList',
    meta: {title: '员工离职', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/resignationList/resignationList'),
  },
  {
    path: 'orgInfo',
    meta: {title: '公司信息', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/orgInfo/orgInfo'),
  },{
    path: 'templateList',
    meta: {title: '模板管理', needLogin: true,isAdmin:false},
    component: () => import ('../views/admin/templateList/templateList'),
  },{
    path: 'orgList',
    meta: {title: '公司列表', needLogin: true,isAdmin:true},
    component: () => import ('../views/admin/orgList/orgList'),
  },{
    path: 'groupList',
    meta: {title: '集团列表', needLogin: true,isAdmin:true},
    component: () => import ('../views/admin/groupList/groupList'),
  },{
    path: 'subList',
    meta: {title: '管理员列表', needLogin: true,isAdmin:true},
    component: () => import ('../views/admin/subList/subList'),
  },

]
export default adminRouter
