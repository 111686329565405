import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import md5 from 'js-md5';
import './plugins/index.js'
import './assets/font/iconfont.css'
import './plugins/element.js'
import './assets/css/reset.css'
import './plugins/vant.js'
import VueClipboard from 'vue-clipboard2'
import VueCropper from 'vue-cropper'
import VueSignaturePad from 'vue-signature-pad';



Vue.prototype.$md5 = md5;
Vue.use(VueSignaturePad);
Vue.use(VueClipboard);
Vue.use(VueCropper)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
