import {ONLINEHOST, QAHOST} from '../config'
import {genUpToken} from "@/utils/CryptoJS";

const requestUrl = process.env.NODE_ENV === 'production' ? ONLINEHOST : QAHOST
/**
 * 获取Token
 */
export const getToken = () => {
  let token = window.localStorage.getItem('token')
  return {token: token ? JSON.parse(token) : ''};
}

export const getOrgId = () => {
  let org_id = window.localStorage.getItem('orgId')
  console.log()
  return {org_id: org_id ? JSON.parse(org_id) : ''};
}


export const getPageName = (url)=>{
  var tmp= new Array();//临时变量，保存分割字符串
  tmp=url.split("/");//按照"/"分割
  var pp = tmp[tmp.length-1];//获取最后一部分，即文件名和参数
  tmp=pp.split("?");//把参数和文件名分割开
  return tmp[0];
}

/*
* 百度编辑器配置
* */
export const bdEditConfig = () => {
  return {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 320,
    // 初始容器宽度
    initialFrameWidth: '100%',
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    // serverUrl: requestUrl + '/editor/controller.php',
    serverUrl: requestUrl + '/editor/controller.php',
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: '/UEditor/',
    //  编辑器功能
    toolbars:[
      ['bold','fontsize','forecolor','backcolor','justifyleft','justifyright','justifycenter','insertorderedlist','insertunorderedlist','removeformat',]
    ]
  }
}

/*
* 获取七牛云token
* */
export const getQnyToken = () => {
  var token;
  var policy = {};
  var bucketName = 'yhyjy';//你的七牛桶名称
  var AK = '_MhT6BWLiDGy2755HTwoxzNH747ecCtlqldVq3p3'; //你的七牛AK
  var SK = '15juIkGIozY13PvocO4o5lckBFb-OzqFrSVAAM7z';//你的七牛SK
  var deadline = Math.round(new Date().getTime() / 1000) + 3600;
  policy.scope = bucketName;
  policy.deadline = deadline;
  token = genUpToken(AK, SK, policy);
  return token
}


/*
* 数据过滤全部
* */
export const optionList = (state, arr) => {
  if (state) {
    return arr
  } else {
    return arr.slice(1)
  }
}

/*
* value转换为label
* */
export const filesCN = (val, arr) => {
  if (val) {
    let _str = arr.filter(item => val === item.value)
    return _str[0].label
  } else {
    return ''
  }

}

export const isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
}

/*
* 时间转换
* */
export const zhDate = (date, type) => {
  let now = new Date(date)
  let year = now.getFullYear()
  let month = now.getMonth() + 1
  let day = now.getDate()
  let hour = now.getHours()
  let minute = now.getMinutes()
  let second = now.getSeconds()
  if (type === 1) {         // 年月日时分秒
    return year + "-" + getzf(month) + "-" + getzf(day) + "  " + getzf(hour) + ":" + getzf(minute) + ":" + getzf(second)
  } else if (type === 2) {  // 年月日时分
    return year + "-" + getzf(month) + "-" + getzf(day) + "  " + getzf(hour) + ":" + getzf(minute)
  } else if (type === 3) {  // 年月
    return year + "-" + getzf(month)
  } else if (type === 4) {  // 年
    return year
  } else if (type === 5) {  // 月
    return month
  } else if (type === 6) {  // 月日时分
    return getzf(month) + "-" + getzf(day) + "  " + getzf(hour) + ":" + getzf(minute)
  } else {                  // 年月日
    return year + "-" + getzf(month) + "-" + getzf(day)
  }
}

function getzf(num) {
  if (parseInt(num) < 10) {
    num = '0' + num
  }
  return num
}



