/**
 * 线上环境
 */
export const ONLINEHOST = 'https://hrapi.shareone.cc'

/**
 * 测试环境
 */
export const QAHOST = 'https://hrapi.shareone.cc'
// export const QAHOST = 'http://8.146.211.14:11135'

/**
 * 上传地址
 */
export const upFile = (process.env.NODE_ENV === 'production' ? ONLINEHOST : QAHOST) + '/index.php/index/Other/upload'
export const TemplateFile = (process.env.NODE_ENV === 'production' ? ONLINEHOST : QAHOST) + '/index.php/index/Other/uploadOrgTemplate'

/**
 *  对外地址
 */
export const openFile =  (process.env.NODE_ENV === 'production' ? ONLINEHOST : QAHOST) + '/'



/*
* 缓存字段
* */
export const localAnStore = [
  {
    key: 'user',
    type: 'localStorage'
  },
  {
    key: 'token',
    type: 'localStorage'
  },
  {
    key: 'orgId',
    type: 'localStorage'
  },{
    key: 'orgName',
    type: 'localStorage'
  },
  {
    key: 'orgList',
    type: 'localStorage'
  },
  {
    key: 'isAdmin',
    type: 'localStorage'
  }
]
