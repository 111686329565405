import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    user: '',
    orgId: '',
    orgName: '',
    isAdmin: false,
    orgList:[]
  },
  mutations: {
    increment(state) {
      // 变更状态
      state.token = ''
      state.user = ''
      state.orgId = ''
      state.orgName = ''
      state.isAdmin= false
      state.orgList = []
    }
  },
  actions: {
    LogOut(context) {
      context.commit('increment')
    }
  },
  modules: {
  }
})
